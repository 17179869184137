import { useTranslation } from 'react-i18next';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { useEffect } from 'react';
import HomeBannerVideo from '../../assets/header-video-compress.mp4'
import FWButton from '../form/FWButton';
import { useNavigate } from 'react-router';

function Banner() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const actionHeadingControl = useAnimation();
  const [actionHeadingRef, actionHeadingInView] = useInView();

  const actionImageControl = useAnimation();
  const [actionImageRef, actionImageInView] = useInView();

  const actionHeadingVariants = {
    visible: {
      opacity: 1,
      x: 0,
      transition: { duration: 0.5 },
    },
    hidden: { opacity: 0, x: -20 },
  };

  const actionImageVariants = {
    visible: {
      opacity: 1,
      x: 0,
      transition: { duration: 0.5 },
    },
    hidden: { opacity: 0, x: -20 },
  };

  useEffect(() => {
    if (actionHeadingInView) actionHeadingControl.start('visible');
    else actionHeadingControl.start('hidden');
  }, [actionHeadingControl, actionHeadingInView]);

  useEffect(() => {
    if (actionImageInView) actionImageControl.start('visible');
    else actionImageControl.start('hidden');
  }, [actionImageControl, actionImageInView]);

  return (
    <>
      {/* Desktop Banner */}
      <div className='banner desktopBanner pt-0 relative w-full'>
        <div className="banner__heading z-20">
          <motion.h1
            className="home-banner-heading"
            ref={actionHeadingRef}
            variants={actionHeadingVariants}
            initial="hidden"
            animate={actionHeadingControl}
            exit="exit"
          >
            {t('homeBanner.homeBannerHeading')}
          </motion.h1>
          <div className="home-banner-SubHeading">
            {t('homeBanner.homeBannerSubHeading')}{' '}
            <span className="home-banner-SubHeading__home-primary">{t('homeBanner.homeBannerPrimary')}</span>
          </div>
          <div className="w-[130px] h-[56px]">
            <FWButton
              title={t('homeBanner.myPlansButton')}
              variant="sky"
              icon="fa-solid fa-arrow-right"
              className="flex gap-1"
              onClick={() => navigate('/user/plannings')}
            />
          </div>
        </div>
        <motion.div
          className='banner__hero z-20'
          ref={actionImageRef}
          variants={actionImageVariants}
          initial='hidden'
          animate={actionImageControl}
        >
          <video
            className='BannerVideo'
            style={{
              borderRadius: '10px',
              width: '100%',
              maxWidth: '400px',
              boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
            }}
            autoPlay
            loop
            muted
            preload="auto"
            playsInline
          >
            <source src="https://planilink.s3.eu-north-1.amazonaws.com/cec9427a-ccb1-4696-9322-79823fad32cc.mp4" type="video/mp4" />
          </video>
        </motion.div>
      </div>
      {/* Mobile Banner */}
      <div className='bannerMobile relative'>
        {/* <img src={HomeBannerMobileImg} alt="" /> */}
        <div style={{ position: 'relative' }}>
          <video
            style={{
              width: '100%',
              boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
            }}
            autoPlay
            loop
            muted
            preload="auto"
            playsInline
          >
            <source src="https://planilink.s3.eu-north-1.amazonaws.com/cec9427a-ccb1-4696-9322-79823fad32cc.mp4" type="video/mp4" />
          </video>
          <div
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: 'rgb(0 0 0 / 63%)',
            }}
          ></div>
        </div>
        <div className='bannerMobileContainer'>
          <h1
            className="home-banner-heading"
          >
            {t('homeBanner.homeBannerHeading')}
          </h1>
          <div className="home-banner-SubHeading">
            {t('homeBanner.homeBannerSubHeading')}{' '}
          </div>
          <span className="home-banner-SubHeading__home-primary">{t('homeBanner.homeBannerPrimary')}</span>
          <div className="w-[130px] h-[56px]">
            <FWButton
              title={t('homeBanner.myPlansButton')}
              variant="sky"
              icon="fa-solid fa-arrow-right"
              className="flex gap-1"
              onClick={() => navigate('/user/plannings')}
            />
          </div>
        </div>
      </div>
    </>
  );
}
export default Banner;
