export const lang = {
  en: {
    patientAdded: 'Patient added successfully!',
    planApproved: 'Plan approved!',
    success: 'Success!',
  },
  es: {
    patientAdded: 'El paciente agregó con éxito!',
    planApproved: 'Planificación aprobada',
    success: 'Éxito',
  },
};
