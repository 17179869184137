import React from 'react'
import { useEffect, useState } from 'react'
import { useInView } from 'react-intersection-observer'
import { motion, useAnimation } from 'framer-motion'
import { useTranslation } from 'react-i18next'
import { Helmet } from 'react-helmet-async'
import BlogImg from '../../assets/BlogsImg.png'
import BlogsCard from './components/BlogsCard'
import { getAllBlogs } from '../../apis/blogApi'
import { useLanguage } from '../../context/languageContext'

function Blog() {
  const { t } = useTranslation()
  const actionHeadingControl = useAnimation()
  const [actionHeadingRef, actionHeadingInView] = useInView()
  const [data, setdata] = useState([])
  const [filteredData, setFilteredData] = useState([])
  const { language } = useLanguage()

  const blogCardControl = useAnimation()
  const [blogCardRef, blogCardInView] = useInView()

  const actionHeadingVariants = {
    visible: {
      opacity: 1,
      x: 0,
      transition: { duration: 0.5 },
    },
    hidden: { opacity: 0, x: -20 },
  }
  const blogCardVariants = {
    visible: {
      opacity: 1,
      x: 0,
      transition: { duration: 0.5 },
    },
    hidden: { opacity: 0, x: -20 },
  }

  const getBlogsForBlogPage = async () => {
    return await getAllBlogs()
  }

  useEffect(() => {
    if (actionHeadingInView) actionHeadingControl.start('visible')
    else actionHeadingControl.start('hidden')
  }, [actionHeadingControl, actionHeadingInView])

  useEffect(() => {
    if (blogCardInView) blogCardControl.start('visible')
    else blogCardControl.start('hidden')
  }, [blogCardControl, blogCardInView])
  useEffect(() => {
    const fetchBlogs = async () => {
      const result = await getBlogsForBlogPage()
      setdata(result['data'])
    }

    fetchBlogs()

    window.scrollTo(0, 0)
  }, [])
  const languageMap = {
    en: 'english',
    es: 'spanish',
  }
  useEffect(() => {
    if (data.length > 0) {
      setFilteredData(
        data.filter((blog) => blog.language === languageMap[language])
      )
    }
  }, [language, data])
  return (
    <div>
      <Helmet>
        <title>Planilink | Blogs</title>
        <meta
          name='description'
          content='planilink plans your plastic orthodontic cases and sends you the sequence of biomodels so that you can make your own aligners on your own.'
          data-r='true'
        />
        <link rel='canonical' href='https://planilink.com/' />

        <meta name='robots' content='index, follow' />

        {/* <!-- For Social Media --> */}
        <meta name='og:card' content='Plastic Orthodontic Plannings' />
        <meta name='og:title' content='planilink' />
        <meta
          name='og:description'
          content='planilink plans your plastic orthodontic cases and sends you the sequence of biomodels so that you can make your own aligners on your own.'
        />
        <meta
          name='og:image'
          content='https://planilink.com/static/media/planilink.ff9c0a0f2617f374f55206377651b2e9.svg'
        />
        <meta property='og:type' content='website' />
        <meta property='og:url' content='https://planilink.com/' />
        {/* <!-- For Social Media --> */}

        {/* <!-- Tags for twitter --> */}

        <meta name='twitter:card' content='Plastic Orthodontic Plannings' />
        <meta name='twitter:title' content='planilink' />
        <meta
          name='twitter:description'
          content='planilink plans your plastic orthodontic cases and sends you the sequence of biomodels so that you can make your own aligners on your own.'
        />
        <meta
          name='twitter:image'
          content='https://planilink.com/static/media/planilink.ff9c0a0f2617f374f55206377651b2e9.svg'
        />
        {/* <!-- Tags for twitter --> */}
      </Helmet>
      <div>
        <div className='BlogsContainer'>
          <motion.div
            ref={actionHeadingRef}
            variants={actionHeadingVariants}
            initial='hidden'
            animate={actionHeadingControl}
          >
            <p className='BlogsContainer__heading'>{t('blogs.blogsHeading')}</p>
          </motion.div>
          <div>
            <motion.div
              ref={blogCardRef}
              variants={blogCardVariants}
              initial='hidden'
              animate={blogCardControl}
              className='grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-7 lg:gap-10 mt-10 lg:mt-20'
            >
              {filteredData.map((blog) => (
                <BlogsCard
                  key={blog._id}
                  language={blog.language}
                  title={blog.title}
                  desc={blog.desc}
                  img={blog.BlogImage || BlogImg}
                  link={`/blogs/${blog._id}`}
                />
              ))}
            </motion.div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default Blog
