import { React, useEffect } from 'react';
import ExclusiveImg from '../../assets/exclusice_treatment.png'
import { useTranslation, Trans } from 'react-i18next';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import LogoText from './LogoText';
export default function ExclusiveTreatment() {
  const { t } = useTranslation();
  const exclusiveHeadingControl = useAnimation();
  const [exclusiveHeadingRef, exclusiveHeadingInView] = useInView();

  const actionHeadingVariants = {
    visible: {
      opacity: 1,
      x: 0,
      transition: { duration: 1.0 },
    },
    hidden: { opacity: 0, x: -30 },
  };


  useEffect(() => {
    if (exclusiveHeadingInView) exclusiveHeadingControl.start('visible');
    else exclusiveHeadingControl.start('hidden');
  }, [exclusiveHeadingControl, exclusiveHeadingInView]);
  return (
    <div>
      <motion.div
        ref={exclusiveHeadingRef}
        variants={actionHeadingVariants}
        initial="hidden"
        animate={exclusiveHeadingControl}
        className='exclusiveTreatmentContainer'>
        <div className='exclusiveTreatmentContainerDesktop'>
          <img src={ExclusiveImg} alt="ExclusiveImg" />
          <div className="py-5 px-7">
            <p className="exclusiveTreatmentContainer__heading">{t('exclusiveTreatment.exclusiveTreatmentHeading')}</p>
            <p className="exclusiveTreatmentContainer__desc">
              <Trans i18nKey="exclusiveTreatment.exclusiveTreatmentDesc1">
                At <LogoText />, we help dental clinics and laboratories optimize their invisible orthodontic treatments, enabling the creation of in-house aligners. We handle the planning and send you the digital files so you can manufacture your own aligners.
              </Trans>
            </p>
            <p className="exclusiveTreatmentContainer__desc">{t('exclusiveTreatment.exclusiveTreatmentDesc2')}</p>
          </div>
        </div>
        <div className='exclusiveTreatmentContainerMobile'>
          <p className="exclusiveTreatmentContainer__heading mb-5 text-center">{t('exclusiveTreatment.exclusiveTreatmentHeading')}</p>
          <div className='flex justify-center'>
            <img src={ExclusiveImg} alt="ExclusiveImg" />
          </div>
          <div>
            <p className="exclusiveTreatmentContainer__desc mt-5">
              <Trans i18nKey="exclusiveTreatment.exclusiveTreatmentDesc1">
                At <LogoText />, we help dental clinics and laboratories optimize their invisible orthodontic treatments, enabling the creation of in-house aligners. We handle the planning and send you the digital files so you can manufacture your own aligners.
              </Trans>
            </p>
            <p className="exclusiveTreatmentContainer__desc">{t('exclusiveTreatment.exclusiveTreatmentDesc2')}</p>
          </div>
        </div>
      </motion.div>
    </div>
  )
}
