import React from 'react'

export default function LogoText() {
    return (
        <span className="LogoText"
        >
            Plani
            <span className="LogoText__redText">Link</span>
        </span>
    )
}
