import React from 'react'
// import { hydrate, render } from 'react-dom';
import ReactDOM from 'react-dom/client'
import App from './App'
import './i18'
import { HelmetProvider } from 'react-helmet-async'
import { LanguageProvider } from './context/languageContext'

// const rootElement = document.getElementById('root');

const root = ReactDOM.createRoot(document.getElementById('root'))

root.render(
  // <React.StrictMode>
  <HelmetProvider>
    <LanguageProvider>
      <App />
    </LanguageProvider>
  </HelmetProvider>

  // </React.StrictMode>
)
