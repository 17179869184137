import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { useInView } from 'react-intersection-observer'
import { useTranslation } from 'react-i18next'
import { motion, useAnimation } from 'framer-motion'
import BlogImg from '../../../assets/BlogsImg.png'
import { getAllBlogs, removeBlogByID } from '../../../apis/blogApi'
import { useNavigate } from 'react-router-dom'

export default function BlogsCreate() {
  const navigate = useNavigate()

  const { t } = useTranslation()
  const actionHeadingControl = useAnimation()
  const [actionHeadingRef, actionHeadingInView] = useInView()
  const [data, setData] = useState([])
  const actionHeadingVariants = {
    visible: {
      opacity: 1,
      x: 0,
      transition: { duration: 0.5 },
    },
    hidden: { opacity: 0, x: -20 },
  }
  const getBlogsForBlogPage = async () => {
    return await getAllBlogs()
  }

  const updateBlog = (id) => {
    navigate(`/admin/edit-blog/${id}`)
  }

  const deleteBlog = async (id) => {
    await removeBlogByID(id)
    await fetchBlogs()
  }

  const fetchBlogs = async () => {
    const result = await getBlogsForBlogPage()
    setData(result['data'])
  }
  useEffect(() => {
    if (actionHeadingInView) actionHeadingControl.start('visible')
    else actionHeadingControl.start('hidden')
  }, [actionHeadingControl, actionHeadingInView])
  useEffect(() => {
    const fetchBlogs = async () => {
      const result = await getBlogsForBlogPage()
      setData(result['data'])
    }

    fetchBlogs()
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
      <Helmet>
        <title>Planilink | All Blogs</title>
        <meta
          name='description'
          content='planilink plans your plastic orthodontic cases and sends you the sequence of biomodels so that you can make your own aligners on your own.'
          data-r='true'
        />
        <link rel='canonical' href='https://planilink.com/' />

        <meta name='robots' content='index, follow' />

        {/* <!-- For Social Media --> */}
        <meta name='og:card' content='Plastic Orthodontic Plannings' />
        <meta name='og:title' content='planilink' />
        <meta
          name='og:description'
          content='planilink plans your plastic orthodontic cases and sends you the sequence of biomodels so that you can make your own aligners on your own.'
        />
        <meta
          name='og:image'
          content='https://planilink.com/static/media/planilink.ff9c0a0f2617f374f55206377651b2e9.svg'
        />
        <meta property='og:type' content='website' />
        <meta property='og:url' content='https://planilink.com/' />
        {/* <!-- For Social Media --> */}

        {/* <!-- Tags for twitter --> */}

        <meta name='twitter:card' content='Plastic Orthodontic Plannings' />
        <meta name='twitter:title' content='planilink' />
        <meta
          name='twitter:description'
          content='planilink plans your plastic orthodontic cases and sends you the sequence of biomodels so that you can make your own aligners on your own.'
        />
        <meta
          name='twitter:image'
          content='https://planilink.com/static/media/planilink.ff9c0a0f2617f374f55206377651b2e9.svg'
        />
        {/* <!-- Tags for twitter --> */}
      </Helmet>
      <motion.div
        ref={actionHeadingRef}
        variants={actionHeadingVariants}
        initial='hidden'
        animate={actionHeadingControl}
        className='adminAllBlogs'
      >
        <p className='BlogsContainer__heading'>{t('blogs.blogsHeading')}</p>
        <div className='grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-7 lg:gap-10 mt-10'>
          {data.map((blog) => (
            <div div className='BlogsCard'>
              <div className='BlogsCard__image mb-5'>
                <img src={blog.BlogImage} alt='BlogImg' className='w-full ' />
              </div>
              <p className='BlogsCard__heading mb-5'>{blog.title}</p>
              <p className='BlogsCard__desc mb-5'>{blog.desc}</p>
              <div className='flex items-center gap-5'>
                <i
                  onClick={() => updateBlog(blog._id)}
                  class='fa-solid fa-pencil'
                ></i>
                <i
                  onClick={() => deleteBlog(blog._id)}
                  class='fa-solid fa-trash'
                ></i>
              </div>
            </div>
          ))}
        </div>
      </motion.div>
    </>
  )
}
