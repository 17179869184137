import axios from 'axios'
import { request } from './request'

export const addBlogApi = (title, description, BlogImage, writer, language) =>
  request({
    url: `/gen/blog`,
    method: 'POST',
    data: { title, description, BlogImage, writer, language },
  })

export const getAllBlogs = () =>
  request({
    url: `/gen/blog/data`,
    method: 'GET',
  })

export const getBlogById = (id) =>
  request({
    url: `/gen/blog/${id}`,
    method: 'GET',
  })

export const removeBlogByID = (id) =>
  request({
    url: `/gen/blog/delete`,
    method: 'POST',
    data: { id },
  })

export const updateBlogByID = (
  id,
  title,
  description,
  BlogImage,
  writer,
  language
) =>
  request({
    url: `/gen/blog/update`,
    method: 'POST',
    data: { id, title, description, BlogImage, writer, language },
  })
