import checkTheme from '../../../../assets/checkHome.svg';
import { useTranslation } from 'react-i18next';
const Cards = ({ title, price }) => {
  const { t } = useTranslation();

  return (
    <div className='xl:w-1/3 md:w-1/2 w-full px-3 py-0 mb-6'>
      <div className='planCardContainer'>
        <div className='planCardContainer__inner'>
          <div className='planCardHome shadow-container pb-0'>
            <h2 className='planCardHome__heading w-4/6'>
              {t('planCard.planningUpto')}
              <span className='color-sky'>{title}</span>
            </h2>
            <div className='line'></div>
            <div className='planCardHome__features'>
              <div className='planCardHome__features__feature'>
                <img src={checkTheme} alt='' />
                {t('planCard.SW')}
              </div>
              <div className='planCardHome__features__feature'>
                <img src={checkTheme} alt='' />
                {t('planCard.simulation')}
              </div>
              <div className='planCardHome__features__feature'>
                <img src={checkTheme} alt='' />
                {t('planCard.DWR')}
              </div>
              <div className='planCardHome__features__feature'>
                <img src={checkTheme} alt='' />
                {t('planCard.BM')}
              </div>
            </div>
            <div className='line'></div>
            <div className='planCardHome__additionalDets'>
              <h5 className='color-sky font-semibold'>{t('planCard.PAC')}</h5>
              <p>{t('planCard.RCB')}</p>
              <div className='planCardHome__additionalDets__price'>
                <span className='planCardHome__additionalDets__price--price'>
                  {price}
                </span>
                <span className='planCardHome__additionalDets__price--euro'>
                  €
                </span>
              </div>
            </div>
          </div>
          {/* Mobile Card */}
          <div className='planCardHomeMob shadow-container'>
            <h2 className='planCardHomeMob__heading'>
              {t('planCard.planningUpto')}
              <span className='color-sky'>{title}</span>
            </h2>
            <div className='line'></div>
            <div className='planCardHomeMob__features'>
              <div className='planCardHomeMob__features__feature'>
                <img src={checkTheme} alt='' />
                {t('planCard.SW')}
              </div>
              <div className='planCardHomeMob__features__feature'>
                <img src={checkTheme} alt='' />
                {t('planCard.simulation')}
              </div>
              <div className='planCardHomeMob__features__feature'>
                <img src={checkTheme} alt='' />
                {t('planCard.DWR')}
              </div>
              <div className='planCardHomeMob__features__feature'>
                <img src={checkTheme} alt='' />
                {t('planCard.BM')}
              </div>
            </div>
            <div className='line'></div>
            <div className='planCardHomeMob__additionalDets'>
              <h5 className='color-sky font-semibold'>{t('planCard.PAC')}</h5>
              <p>{t('planCard.RCB')}</p>
              <div className='planCardHomeMob__additionalDets__price'>
                <span className='planCardHomeMob__additionalDets__price--price'>
                  {price}
                </span>
                <span className='planCardHomeMob__additionalDets__price--euro'>
                  €
                </span>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Cards;
