import React, { useEffect, useState } from 'react'
import BlogShowImg from '../../../assets/BlogShowImg.png'
import { useInView } from 'react-intersection-observer'
import { motion, useAnimation } from 'framer-motion'
import { getBlogById } from '../../../apis/blogApi'

export default function BlogsShow() {
  const actionHeadingControl = useAnimation()
  const [actionHeadingRef, actionHeadingInView] = useInView()
  const [data, setdata] = useState([])

  const getDate = (isoString) => {
    const date = new Date(isoString)
    const dayName = date.toLocaleDateString('en-US', { weekday: 'long' })
    const monthName = date.toLocaleDateString('en-US', { month: 'long' })
    const day = date.getDate()
    return `${dayName} ${day} ${monthName}`
  }

  const actionHeadingVariants = {
    visible: {
      opacity: 1,
      x: 0,
      transition: { duration: 0.5 },
    },
    hidden: { opacity: 0, x: -20 },
  }

  const getBlogByid = async () => {
    const path = window.location.pathname
    const id = path.split('/').pop()
    return await getBlogById(id)
  }

  useEffect(() => {
    if (actionHeadingInView) actionHeadingControl.start('visible')
    else actionHeadingControl.start('hidden')
  }, [actionHeadingControl, actionHeadingInView])
  useEffect(() => {
    const fetchBlogById = async () => {
      const result = await getBlogByid()
      setdata(result['data'])
    }

    fetchBlogById()

    window.scrollTo(0, 0)
  }, [])
  return (
    <motion.div
      ref={actionHeadingRef}
      variants={actionHeadingVariants}
      initial='hidden'
      animate={actionHeadingControl}
      className='BlogsShow'
    >
      <div className='BlogsShow__img'>
        <img src={data.BlogImage} alt='BlogShowImg' />
      </div>
      <div className='BlogsShowContainer'>
        <p className='BlogsShowContainer__heading mb-3 lg:mb-5'>{data.title}</p>
        <div className='flex gap-5 items-center mb-5 lg:mb-10'>
          <span className='BlogsShowContainer__writter'>
            Written by {data.writer}
          </span>
          <span className='BlogsShowContainer__dayTime'>
            {getDate(data.createdAt)}
          </span>
        </div>
        <p
          className='mb-5'
          dangerouslySetInnerHTML={{ __html: data.description }}
        />
      </div>
    </motion.div>
  )
}
