import { React, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { useNavigate } from 'react-router';
import FWButton from '../../../../components/form/FWButton';
import TreatmentPlansCard from './TreatmentPlansCard'
import TreatmentPlansCardImg1 from '../../../../assets/TreatmentPlansCardImg1.png'
import TreatmentPlansCardImg2 from '../../../../assets/TreatmentPlansCardImg2.png'
import TreatmentPlansCardImg3 from '../../../../assets/TreatmentPlansCardImg3.png'

export default function TreatmentPlans() {
  const { t } = useTranslation();
  const [isInView, setIsInView] = useState(false);
  const navigate = useNavigate();
  const makeItPossibleContainerControl = useAnimation();
  const [containerRef, containerInView] = useInView();

  useEffect(() => {
    if (containerInView) makeItPossibleContainerControl.start('visible');
    else makeItPossibleContainerControl.start('hidden');
  }, [makeItPossibleContainerControl, containerInView]);

  const containerVariants = {
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 1.5,
        ease: 'easeInOut',
      },
    },
    hidden: {
      opacity: 0,
      y: 50,
      transition: {
        duration: 1.5,
        ease: 'easeInOut',
      },
    },
  };

  const treatmentPlansData = [
    {
      titleKey: 'treatmentPlans.card1.title',
      img: TreatmentPlansCardImg1,
      url: "https://downloads-default.nemocloud-services.com/DownloadUploadService/nemobox/viewer/shared/doctor?centerId=185-73-27-27&docId=b793280e-1f1f-4fde-aceb-1d072b3310fa&userId=448-91-59-42"
    },
    {
      titleKey: 'treatmentPlans.card2.title',
      img: TreatmentPlansCardImg2,
      url:"https://downloads-default.nemocloud-services.com/DownloadUploadService/nemobox/viewer/shared/doctor?centerId=185-73-27-27&docId=e5ec06e8-96b8-4603-8f22-024c33a42122&userId=448-91-59-42"
    },
    {
      titleKey: 'treatmentPlans.card3.title',
      img: TreatmentPlansCardImg3,
      url: "https://downloads-default.nemocloud-services.com/DownloadUploadService/nemobox/viewer/shared/doctor?centerId=185-73-27-27&docId=223a6574-9cf3-4057-9947-a1ce738166ce&userId=448-91-59-42"
    }
  ];
  return (
    <div className='TreatmentPlans relative'>
      <div className='TreatmentPlansBG'>
        <motion.div
          ref={containerRef}
          variants={containerVariants}
          initial="hidden"
          animate={makeItPossibleContainerControl}
          className='TreatmentPlansContainer'>
          <div className='text-center'>
            <p className='TreatmentPlansContainer__heading'>{t('treatmentPlans.heading')}</p>
            <p className='TreatmentPlansContainer__subHeading'>{t('treatmentPlans.subHeading')}</p>
          </div>
          <div className="flex flex-wrap justify-center -mx-3 -mt-0"
            ref={(el) => {
              if (el) {
                const rect = el.getBoundingClientRect();
                setIsInView(rect.top < window.innerHeight && rect.bottom > 0);
              }
            }}>
            {treatmentPlansData.map((plan, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, y: 0 }}
                animate={{
                  opacity: 1,
                  y: isInView ? 0 : 0,
                  transition: {
                    delay: index * 0.3,
                    duration: 1,
                    type: 'spring',
                  },
                }}
                style={{
                  transform: isInView ? `translateY(${(index + 1) * 20}px)` : 'translateY(0)',
                }}
                className="card-container xl:w-1/3 md:w-1/2 w-full px-3 py-0 mb-6"
              >
                <TreatmentPlansCard
                  key={index}
                  title={t(plan.titleKey)}
                  img={plan.img}
                  url={plan.url}
                />
              </motion.div>
            ))}
          </div>
          <div className='flex justify-center'>
            <div className="w-[130px] h-[56px]">
              <FWButton
                title={t('homeBanner.myPlansButton')}
                variant="sky"
                icon="fa-solid fa-arrow-right"
                className="flex gap-1"
                onClick={() => navigate('/user/plannings')}
              />
            </div>
          </div>
        </motion.div>
      </div>
    </div >
  )
}
