import React from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import logo from '../../assets/planilink.svg'
import FWButton from '../form/FWButton'
import { useGlobalStore } from '../../store/store'
import { changeLanguage } from 'i18next'
import useHelpers from '../../hooks/useHelpers'
import { setUserLangAPI } from '../../apis/userAPIs'
import { twMerge } from 'tailwind-merge'
import UKflag from '../../assets/uk-flag.png'
import Spainflag from '../../assets/spain.png'
import { useLanguage } from '../../context/languageContext'

function NavBar() {
  const { switchLanguage } = useLanguage()
  const { t } = useTranslation()
  const { lang, changeLang, updateUser, user } = useGlobalStore()
  const { navigateToPage } = useHelpers()
  const location = useLocation()
  const navigate = useNavigate()

  const handleLanguageChange = (languageCode) => {
    changeLang(languageCode)
    changeLanguage(languageCode)
  }

  const navButtonData = [
    { title: t('navbar.buttons.home'), path: '/' },
    { title: t('navbar.buttons.blog'), path: '/blogs' },
    { title: t('navbar.buttons.questions'), path: '/faq/web' },
    { title: t('navbar.buttons.downloadGuide'), path: '/aligners' },
  ]

  const updateLangInDB = async (languageCode) => {
    if (user) {
      const response = await setUserLangAPI(languageCode, user._id)
      if (response) {
        updateUser(response.user)
      }
    }
  }

  const handleButtonClick = (path) => {
    navigate(path)
  }

  return (
    <div className='navbar z-50'>
      <img className='w-[152px] h-[51px]' src={logo} alt='logo' />
      <div className='flex gap-[16px]'>
        {navButtonData.map((button, index) => (
          <div key={index}>
            <FWButton
              title={button.title}
              variant='nothing'
              onClick={() => handleButtonClick(button.path)}
              className={twMerge(
                'z-50',
                location.pathname === button.path ? 'active' : ''
              )}
            />
          </div>
        ))}
      </div>
      <div className='flex gap-[24px] z-30'>
        <div className='w-[170px] bg-[#222539] rounded-full flex navBarFlags'>
          <FWButton
            img={UKflag}
            // title="English"
            variant={lang === 'en' ? 'sky' : 'nothing'}
            onClick={() => {
              handleLanguageChange('en')
              updateLangInDB('en')
              switchLanguage('en')
            }}
          />
          <FWButton
            img={Spainflag}
            // title="Spanish"
            variant={lang === 'es' ? 'sky' : 'nothing'}
            onClick={() => {
              handleLanguageChange('es')
              updateLangInDB('es')
              switchLanguage('es')
            }}
          />
        </div>
        <div >
          <FWButton
            title={t('homeBanner.myPlansButton')}
            variant='transparent'
            icon='fa-solid fa-arrow-right'
            className='flex gap-1'
            onClick={() => navigateToPage('/user/plannings')}
          />
        </div>
      </div>
    </div>
  )
}

export default NavBar
