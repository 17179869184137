import React from 'react'

export default function OrthodonticsCard(props) {
    return (
        <div className='OrthodonticsCardContainer'>
            <div className='OrthodonticsCardContainer__inner'>
                <div className='OrthodonticsCardHome'>
                    <div className='flex gap-4 items-center mb-4'>
                        <img className='OrthodonticsCardHome__img' src={props.imgSrc} alt={props.title} />
                        <h2 className='OrthodonticsCardHome__heading'>{props.title}</h2>
                    </div>
                    <p className='OrthodonticsCardHome__desc'>{props.description}</p>
                </div>
            </div>
        </div>
    )
}
