import React from 'react';
import { useTranslation } from 'react-i18next';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { useEffect } from 'react';
import makeItPossibleImg1 from '../../../../assets/makeItPossibleImg1.png'
import makeItPossibleImg2 from '../../../../assets/makeItPossibleImg2.png'
import makeItPossibleImg3 from '../../../../assets/makeItPossibleImg3.png'

const MakeItPossible = () => {
  const { t } = useTranslation();
  const actionHeadingControl = useAnimation();
  const [actionHeadingRef, actionHeadingInView] = useInView();

  const actionCardControl = useAnimation();
  const [actionCardRef, actionCardInView] = useInView();

  const actionHeadingVariants = {
    visible: {
      opacity: 1,
      x: 0,
      transition: { duration: 0.5 },
    },
    hidden: { opacity: 0, x: -20 },
  };
  const actionCardVariants = {
    visible: {
      opacity: 1,
      x: 0,
      transition: { duration: 0.5 },
    },
    hidden: { opacity: 0, x: -20 },
  };

  useEffect(() => {
    if (actionHeadingInView) actionHeadingControl.start('visible');
    else actionHeadingControl.start('hidden');
  }, [actionHeadingControl, actionHeadingInView]);

  useEffect(() => {
    if (actionCardInView) actionCardControl.start('visible');
    else actionCardControl.start('hidden');
  }, [actionCardControl, actionCardInView]);

  return (
    <>
      <div className='makeItPossible'>
        <motion.div
          ref={actionHeadingRef}
          variants={actionHeadingVariants}
          initial='hidden'
          animate={actionHeadingControl}
          className='makeItPossible__heading z-20'
        >
          {t('home.How to make it possible')}
        </motion.div>
        <motion.div
          ref={actionCardRef}
          variants={actionCardVariants}
          initial='hidden'
          animate={actionCardControl}
          className="makeItPossible__container z-20">
          <div className="flex flex-wrap justify-center -mx-3 -mt-0">
            <div className='xl:w-1/3 md:w-1/2 w-full px-3 py-0 mb-6'>
              <div className="makeItPossible__card">
                <div className="makeItPossible__card__inner">
                  <img src={makeItPossibleImg1} alt="makeItPossibleImg1" className="mb-3" />
                  <p className="makeItPossible__card__heading mb-3">{t('makeItPossible.card1.title')}</p>
                  <p className="makeItPossible__card__desc">{t('makeItPossible.card1.description')}</p>
                </div>
              </div>
            </div>
            <div className='xl:w-1/3 md:w-1/2 w-full px-3 py-0 mb-6'>
              <div className="makeItPossible__card">
                <div className="makeItPossible__card__inner">
                  <img src={makeItPossibleImg2} alt="makeItPossibleImg2" className="mb-3" />
                  <p className="makeItPossible__card__heading mb-3">{t('makeItPossible.card2.title')}</p>
                  <p className="makeItPossible__card__desc">{t('makeItPossible.card2.description')}</p>
                </div>
              </div>
            </div>
            <div className='xl:w-1/3 md:w-1/2 w-full px-3 py-0 mb-6'>
              <div className="makeItPossible__card">
                <div className="makeItPossible__card__inner">
                  <img src={makeItPossibleImg3} alt="makeItPossibleImg3" className="mb-3" />
                  <p className="makeItPossible__card__heading mb-3">{t('makeItPossible.card3.title')}</p>
                  <p className="makeItPossible__card__desc">{t('makeItPossible.card3.description')}</p>
                </div>
              </div>
            </div>
          </div>
        </motion.div>
      </div>
    </>
  )
};

export default MakeItPossible;
