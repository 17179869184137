import React from 'react';
import { twMerge } from 'tailwind-merge';

const FWButton = ({
  title,
  img,
  icon,
  variant,
  type,
  onClick,
  style,
  className,
}) => {
  return (
    <button
      className={twMerge(`fWButton_${variant}`, className)}
      type={type}
      onClick={onClick}
      style={style}
    >
      <img src={img} />
      {title}
      <i className={icon}></i>
    </button>
  );
};

export default FWButton;
