import React, { useState, Fragment } from 'react';
import burger from '../../assets/burger.svg';
import SideBarHome from './SideBarHome';
import logo from '../../assets/planilink.svg';
import { useGlobalStore } from '../../store/store';
import { langugeMockData } from '../../shared/mock';
import { changeLanguage } from 'i18next';
import { twMerge } from 'tailwind-merge';
import { setUserLangAPI } from '../../apis/userAPIs';
import { useNavigate } from 'react-router';
import { Menu, Transition } from '@headlessui/react';
import downArrow from '../../assets/down_arrow.svg';
import FWButton from '../form/FWButton';
import { useTranslation } from 'react-i18next';



const MobileNav = () => {
  const { t } = useTranslation();
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const toggleSidebar = () => setIsSidebarOpen(pS => !pS);
  const navigate = useNavigate();
  const { lang, changeLang, user, updateUser } = useGlobalStore();

  const languages = langugeMockData.languages;
  const selectedLanguageData = languages.find(
    language => language.key === lang
  );

  const handleLanguageChange = languageCode => {
    changeLang(languageCode);
    changeLanguage(languageCode);
  };

  const updateLangInDB = async languageCode => {
    if (user) {
      const response = await setUserLangAPI(languageCode, user._id);
      if (response) {
        updateUser(response.user);
      }
    }
  };

  return (
    <>
      <div className='mobnavbar z-30'>
        <img className='w-[110px] h-[46px]' src={logo} alt='logo' />
        <div className='flex items-center gap-2'>
          <div className='mobnavbar__right'>
            <div>
              <Menu as='div' className='relative inline-block text-left z-30 '>
                <div>
                  <Menu.Button>
                    {selectedLanguageData && (
                      <div className='flex gap-3 items-center'>
                        <i className='fa-solid fa-chevron-down text-white'></i>

                        <img
                          src={selectedLanguageData.img}
                          alt={`${selectedLanguageData.value} flag`}
                          className='rounded-full w-6 h-6 mr-2'
                        />
                      </div>
                    )}
                  </Menu.Button>
                </div>
                <Transition
                  as={Fragment}
                  enter='transition ease-out duration-100'
                  enterFrom='transform opacity-0 scale-95'
                  enterTo='transform opacity-100 scale-100'
                  leave='transition ease-in duration-75'
                  leaveFrom='transform opacity-100 scale-100'
                  leaveTo='transform opacity-0 scale-95'
                >
                  <Menu.Items className='absolute overflow-hidden right-0 mt-2 origin-top-right bg-[#2D3045] rounded-md shadow-lg focus:outline-none'>
                    {languages.map((language, index) => (
                      <Menu.Item key={index}>
                        {({ active }) => (
                          <div
                            onClick={() => {
                              handleLanguageChange(language.key);
                              updateLangInDB(language.key);
                            }}
                            className={`${twMerge(
                              'flex items-center justify-between py-2 px-4 cursor-pointer'
                            )} `}
                          >
                            <div className='flex gap-2 items-center'>
                              <span
                                className={twMerge(
                                  'uppercase w-5 text-[#737794]',
                                  (active || lang === language.key) && 'text-white'
                                )}
                              >
                                {language.key}
                              </span>
                              <div className='w-6 h-6 rounded-ful'>
                                <img
                                  className='rounded-full w-6 h-6'
                                  src={language.img}
                                  alt={`${language.value} flag`}
                                />{' '}
                              </div>
                            </div>
                          </div>
                        )}
                      </Menu.Item>
                    ))}
                  </Menu.Items>
                </Transition>
              </Menu>
            </div>
            <div>
              <FWButton
                title={t('homeBanner.myPlansButton')}
                icon='fa-solid fa-arrow-right'
                className='flex'
                variant='mobtransparent'
                style={{ fontSize: '0.75rem' }}
                onClick={() => navigate('/user/plannings')}
              />
            </div>
          </div>
          <img className='' src={burger} alt='burger' onClick={toggleSidebar} />
        </div>
      </div>
      {isSidebarOpen && <SideBarHome toggleSidebar={toggleSidebar} />}
    </>
  );
};

export default MobileNav;
