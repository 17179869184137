import axios from 'axios'
import { request } from './request'

export const getPreSignedURLAPI = ({ type, fileName }) =>
  request({
    url: `/gen/uploads/preSignedURL`,
    method: 'POST',
    data: { fileType: type, fileName },
  })

// Upload Image
export const uploadToS3 = async (url, file) =>
  axios.put(url, file, {
    headers: {
      ContentType: file.type,
    },
  })

export const UploadLocal = (file) => {
  const formData = new FormData()
  formData.append('file', file)
  return request({
    url: `/gen/uploadLocal/upload`,
    method: 'POST',
    data: formData,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
}
