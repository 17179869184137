import React, { createContext, useState, useContext } from 'react';

// Create Context
const LanguageContext = createContext();

// Provider Component
export const LanguageProvider = ({ children }) => {
    const [language, setLanguage] = useState('en'); // Default language

    const switchLanguage = (lang) => {
        setLanguage(lang);
    };

    return (
        <LanguageContext.Provider value={{ language, switchLanguage }}>
            {children}
        </LanguageContext.Provider>
    );
};

// Custom Hook to Use Language Context
export const useLanguage = () => useContext(LanguageContext);
