import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { useInView } from 'react-intersection-observer'
import { motion, useAnimation } from 'framer-motion'
import 'draft-js/dist/Draft.css'
import { getBlogById, updateBlogByID } from '../../../apis/blogApi'
import {
  getPreSignedURLAPI,
  uploadToS3,
  UploadLocal,
} from '../../../apis/uploadAPIs'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import { useNavigate } from 'react-router-dom'
import { useParams } from 'react-router-dom'

export default function BlogsCreate() {
  const { id } = useParams()

  const navigate = useNavigate()
  const actionHeadingControl = useAnimation()
  const [actionHeadingRef, actionHeadingInView] = useInView()
  const [title, setTitle] = useState('')

  const [authorName, setAuthorName] = useState('')
  const [uploadedImage, setUploadedImage] = useState(null)
  const [language, setlanguage] = useState('english')
  const [isLoading, setIsloading] = useState(false)
  const [isDeleted, setisDeleted] = useState(false)
  const [originalFile, setOriginalFile] = useState(null)
  const [value, setValue] = useState('')

  function generateRandomString(length = 12) {
    const characters =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
    let result = ''
    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length)
      result += characters.charAt(randomIndex)
    }
    return result
  }

  const getBlogData = () => {
    return getBlogById(id)
  }

  const SaveBlogData = async () => {
    let filePath = ''
    if (uploadedImage != null && uploadedImage != '' && setisDeleted) {
      const response = await UploadLocal(originalFile)
      filePath = response.fileUrl
    }
    updateBlogByID(id, title, value, filePath, authorName, language)
    setTitle('')
    setAuthorName('')
    setUploadedImage('')
    setOriginalFile(null)
    setlanguage('')
    navigate('/admin/all-blogs')
  }

  const handleChangeLanguage = (event) => {
    setlanguage(event.target.value)
  }

  const handleTitleChange = (e) => {
    setTitle(e.target.value)
  }
  const handleauthorNameChange = (e) => {
    setAuthorName(e.target.value)
  }

  const actionHeadingVariants = {
    visible: {
      opacity: 1,
      x: 0,
      transition: { duration: 0.5 },
    },
    hidden: { opacity: 0, x: -20 },
  }

  useEffect(() => {
    if (actionHeadingInView) actionHeadingControl.start('visible')
    else actionHeadingControl.start('hidden')
  }, [actionHeadingControl, actionHeadingInView])
  useEffect(() => {
    window.scrollTo(0, 0)

    const getBlogByID = async () => {
      const result = await getBlogData()

      setTitle(result.data.title)
      setAuthorName(result.data.writer)
      setValue(result.data.description)
      setlanguage(result.data.language)
      setUploadedImage(result.data.BlogImage)
    }
    getBlogByID()
  }, [])

  const handleFileChange = (event) => {
    const file = event.target.files[0]
    if (file) {
      setOriginalFile(file)
      const reader = new FileReader()
      reader.onload = () => {
        setUploadedImage(reader.result)
      }
      reader.readAsDataURL(file)
    }
  }

  const handleButtonClick = () => {
    document.getElementById('fileInput').click()
  }

  const handleDeleteImage = () => {
    setUploadedImage(null)
    setOriginalFile(null)
    setisDeleted(true)
  }

  // Text Editor
  const modules = {
    toolbar: [
      [{ size: ['small', false, 'large', 'huge'] }],
      ['bold', 'italic', 'underline', 'strike'],
      [{ color: [] }, { background: [] }],
      [{ list: 'ordered' }, { list: 'bullet' }],
      [{ align: [] }],
      ['clean'],
    ],
  }
  const formats = [
    'size',
    'bold',
    'italic',
    'underline',
    'strike',
    'color',
    'background',
    'list',
    'bullet',
    'align',
  ]

  return (
    <>
      <Helmet>
        <title>Planilink | Edit Blog</title>
        <meta
          name='description'
          content='planilink plans your plastic orthodontic cases and sends you the sequence of biomodels so that you can make your own aligners on your own.'
          data-r='true'
        />
        <link rel='canonical' href='https://planilink.com/' />

        <meta name='robots' content='index, follow' />

        {/* <!-- For Social Media --> */}
        <meta name='og:card' content='Plastic Orthodontic Plannings' />
        <meta name='og:title' content='planilink' />
        <meta
          name='og:description'
          content='planilink plans your plastic orthodontic cases and sends you the sequence of biomodels so that you can make your own aligners on your own.'
        />
        <meta
          name='og:image'
          content='https://planilink.com/static/media/planilink.ff9c0a0f2617f374f55206377651b2e9.svg'
        />
        <meta property='og:type' content='website' />
        <meta property='og:url' content='https://planilink.com/' />
        {/* <!-- For Social Media --> */}

        {/* <!-- Tags for twitter --> */}

        <meta name='twitter:card' content='Plastic Orthodontic Plannings' />
        <meta name='twitter:title' content='planilink' />
        <meta
          name='twitter:description'
          content='planilink plans your plastic orthodontic cases and sends you the sequence of biomodels so that you can make your own aligners on your own.'
        />
        <meta
          name='twitter:image'
          content='https://planilink.com/static/media/planilink.ff9c0a0f2617f374f55206377651b2e9.svg'
        />
        {/* <!-- Tags for twitter --> */}
      </Helmet>
      <motion.div
        ref={actionHeadingRef}
        variants={actionHeadingVariants}
        initial='hidden'
        animate={actionHeadingControl}
      >
        <div className='BlogsCreateContainer'>
          <p className='BlogsCreateContainer__heading mb-3 lg:mb-5'>
            Blog Details
          </p>
          <form>
            <div className='flex justify-between lg:items-end items-start'>
              <div className='BlogsCreateContainer__fields w-2/4'>
                <div>
                  <label htmlFor='language'>Select Language</label>
                  <select
                    onChange={handleChangeLanguage}
                    name='language'
                    id='language'
                    className='block lg:w-2/4 w-full'
                  >
                    <option value='english'>English</option>
                    <option value='spanish'>Spanish</option>
                  </select>
                </div>
                <div className='mt-10'>
                  <label htmlFor='title'>Title</label>
                  <input
                    onChange={handleTitleChange}
                    type='text'
                    name='title'
                    id='title'
                    value={title}
                    className='block lg:w-2/4 w-full'
                  />
                </div>
                <div className='mt-10'>
                  <label htmlFor='author'>Author Name</label>
                  <input
                    type='text'
                    onChange={handleauthorNameChange}
                    name='author'
                    id='author'
                    value={authorName}
                    className='block lg:w-2/4 w-full'
                  />
                </div>
              </div>
              <div className='w-1/3'>
                <div className='BlogsCreateContainer__imageSection'>
                  {!uploadedImage ? (
                    <div>
                      <p>Max 5mb, PNG, JPG</p>
                      <p
                        onClick={handleButtonClick}
                        className='BlogsCreateContainer__imageSection__button mt-3'
                      >
                        Browse Files
                      </p>
                      <input
                        id='fileInput'
                        type='file'
                        className='hidden'
                        accept='image/png, image/jpeg'
                        onChange={handleFileChange}
                      />
                    </div>
                  ) : (
                    <div>
                      <img src={uploadedImage} alt='Uploaded' />
                    </div>
                  )}
                </div>
                {uploadedImage && (
                  <div className='flex justify-center'>
                    <p
                      className='deleteImgBtn cursor-pointer text-red-500'
                      onClick={handleDeleteImage}
                    >
                      Delete Image
                    </p>
                  </div>
                )}
              </div>
            </div>
            <div className='mt-10'>
              <div className='BlogsCreateContainer__fields lg:w-6/12 w-full'>
                <div>
                  <label htmlFor='bodyText'>Body Text</label>
                  <div className='mt-3'>
                    <div className='QuillEditor'>
                      <ReactQuill
                        theme='snow'
                        value={value}
                        onChange={setValue}
                        modules={modules}
                        formats={formats}
                      />
                    </div>
                  </div>
                </div>
                {/* <div
              className="ql-editor"
              dangerouslySetInnerHTML={{ __html: value }}
            /> */}
              </div>
            </div>
            <div
              onClick={() => SaveBlogData()}
              className='mt-10 BlogsCreateContainer__submitBtn'
            >
              <button type='button'>Submit</button>
            </div>
          </form>
        </div>
      </motion.div>
    </>
  )
}
