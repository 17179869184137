import { React, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import OrthodonticsCard from './OrthodonticsCard';
import OrthodonticsCardImg1 from '../../../../assets/Invisible_Orthodontics_Card_Img1.png';
import OrthodonticsCardImg2 from '../../../../assets/Invisible_Orthodontics_Card_Img2.png';
import OrthodonticsCardImg3 from '../../../../assets/Invisible_Orthodontics_Card_Img3.png';
import OrthodonticsCardImg4 from '../../../../assets/Invisible_Orthodontics_Card_Img4.png';

export default function InvisibleOrthodontics() {
    const { t } = useTranslation();
    const [isInView, setIsInView] = useState(false);

    const orthodonticsContainerControl = useAnimation();
    const [containerRef, containerInView] = useInView();

    useEffect(() => {
        if (containerInView) orthodonticsContainerControl.start('visible');
        else orthodonticsContainerControl.start('hidden');
    }, [orthodonticsContainerControl, containerInView]);

    const containerVariants = {
        visible: {
            opacity: 1,
            y: 0,
            transition: {
                duration: 1.5,
                ease: 'easeInOut',
            },
        },
        hidden: {
            opacity: 0,
            y: 50,
            transition: {
                duration: 1.5,
                ease: 'easeInOut',
            },
        },
    };

    const OrthodonticsCardData = [
        { imgSrc: OrthodonticsCardImg1, title: t('orthodontics.card1.title'), description: t('orthodontics.card1.description') },
        { imgSrc: OrthodonticsCardImg2, title: t('orthodontics.card2.title'), description: t('orthodontics.card2.description') },
        { imgSrc: OrthodonticsCardImg3, title: t('orthodontics.card3.title'), description: t('orthodontics.card3.description') },
        { imgSrc: OrthodonticsCardImg4, title: t('orthodontics.card4.title'), description: t('orthodontics.card4.description') }
    ];

    return (
        <div>
            <div className='InvisibleOrthodontics relative'>
                <div className='InvisibleOrthodonticsGB'>
                    <motion.div
                        ref={containerRef}
                        variants={containerVariants}
                        initial="hidden"
                        animate={orthodonticsContainerControl}
                        className='InvisibleOrthodonticsContainer'>
                        <p
                            className='InvisibleOrthodonticsContainer__heading mb-20'
                        >
                            {t('orthodontics.heading.part1')}
                            <span className='InvisibleOrthodonticsContainer__heading__redText'>
                                {t('orthodontics.heading.part2')}
                            </span>
                            {t('orthodontics.heading.part3')}
                        </p>

                        <div
                            className='flex flex-wrap -mx-3 -mt-0'
                            ref={(el) => {
                                if (el) {
                                    const rect = el.getBoundingClientRect();
                                    setIsInView(rect.top < window.innerHeight && rect.bottom > 0);
                                }
                            }}
                        >
                            {OrthodonticsCardData.map((card, index) => (
                                <motion.div
                                    key={index}
                                    initial={{ opacity: 0, y: 0 }}
                                    animate={{
                                        opacity: 1,
                                        y: isInView ? 0 : 0,
                                        transition: {
                                            delay: index * 0.3,
                                            duration: 1,
                                            type: 'spring',
                                        },
                                    }}
                                    style={{
                                        transform: isInView ? `translateY(${(index + 1) * 20}px)` : 'translateY(0)',
                                    }}
                                    className="card-container md:w-1/2 w-full px-3 py-0 mb-6"
                                >
                                    <OrthodonticsCard
                                        imgSrc={card.imgSrc}
                                        title={card.title}
                                        description={card.description}
                                    />
                                </motion.div>
                            ))}
                        </div>
                    </motion.div>
                </div>
            </div>
        </div>
    );
}
