import React from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

export default function BlogsCard(props) {
  const { t } = useTranslation()
  return (
    <div className='BlogsCard'>
      <div className='BlogsCard__inner'>
        <div className='BlogsCard__image mb-5'>
          <img src={props.img} alt='BlogImg' className='w-full ' />
        </div>
        <p className='BlogsCard__heading mb-5'>{props.title}</p>
        <p className='BlogsCard__desc mb-5'>{props.desc}</p>
        <Link to={props.link}>
          <div className='BlogsCard__link'>{t('blogs.seeMore')}</div>
        </Link>
      </div>
    </div>
  )
}
