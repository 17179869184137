import { Navigate, useNavigate } from 'react-router'
import { useGlobalStore } from '../../store/store'
import Loading from './Loading'
import { authenticateUser } from '../../apis/authAPIs'
import { useEffect, useRef, useState } from 'react'

const PrivateAdminRoutes = ({ children }) => {
  const navigate = useNavigate()
  const userRef = useRef()
  const { user, updateUser } = useGlobalStore((state) => state)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    userRef.current = user
  }, [user])

  useEffect(() => {
    if (!user?.type === 'admin')
      authenticateUser()
        .then((res) => {
          userRef.current = res.user
          setLoading(false)
          updateUser(res.user)
        })
        .finally(() => {
          setLoading(false)
        })
  }, [])

  if (loading) return <Loading />

  if (user && user.type === 'admin') {
    return children
  } else {
    window.location.href = `${process.env.REACT_APP_FRONTEND_URL}/auth/signin`
  }
}

export default PrivateAdminRoutes
