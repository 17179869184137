import React from 'react'
import ReviewTreatmentPlan from '../../../../assets/ReviewTreatmentPlan.png'
import { useTranslation } from 'react-i18next';

export default function TreatmentPlansCard(props) {
  const handleClick = () => {
    // Open the URL in a new tab
    window.open(props.url, '_blank');
  };
  const { t } = useTranslation();
  return (
    <div className='TreatmentPlansContainer__card'>
      <p className='TreatmentPlansContainer__card__heading'>{props.title}</p>
      <img src={props.img} alt="props.title" className='w-full TreatmentPlansContainer__card__img' />
      <div className='flex flex-col justify-center items-center lg:gap-5 gap-3 lg:py-10 py-7 cursor-pointer'
      onClick={handleClick}>
        <img src={ReviewTreatmentPlan} alt="ReviewTreatmentPlan" />
        <p className='TreatmentPlansContainer__card__review'>{t('treatmentPlans.reviewPlan')}</p>
      </div>
    </div>
  )
}
