import React, { useEffect } from 'react';
import Cards from './Cards';
import { useTranslation } from 'react-i18next';
import { useAnimation, motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

const PlanningToChoose = () => {
  const { t } = useTranslation();
  const actionControl = useAnimation();
  const [actionRef, actionInView] = useInView();

  const actionVariants = {
    visible: {
      opacity: 1,
      x: 0,
      transition: { duration: 1 },
    },
    hidden: { opacity: 0, x: -20 },
  };

  useEffect(() => {
    if (actionInView) actionControl.start('visible');
    else actionControl.start('hidden');
  }, [actionControl, actionInView]);

  return (
    <motion.div
      className='cards relative'
      ref={actionRef}
      variants={actionVariants}
      initial='hidden'
      animate={actionControl}
    >
      <div className='flex justify-center'>
        <div className='cards__heading z-20 xl:w-8/12'>
          <p>{t('planningCard.heading')}</p>
        </div>
      </div>
      <div className='PlanningCards'>
        <div className='flex flex-wrap justify-center -mx-3 -mt-0'>
          <Cards
            title={t('planCard.aligners5')}
            price={90}
          />
          <Cards
            title={t('planCard.aligners10')}
            price={130}
          />
          <Cards
            title={t('planCard.aligners15')}
            price={160}
          />
          <Cards
            title={t('planCard.aligners25')}
            price={180}
          />
          <Cards
            title={t('planCard.alignersMore')}
            price={190}
          />
        </div>
      </div>
    </motion.div>
  )
};

export default PlanningToChoose;
